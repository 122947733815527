import React from 'react'
import parseIcon from './parseIcon'

const parseStepper = (array) => {
    return array.map(icon => {
        const IconRender = parseIcon(icon)
        return <IconRender />
    }).reduce((result, item, index) => {
        result[index + 1] = item;
        return result;
    }, {})
}

export default parseStepper