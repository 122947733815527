import React from 'react'
import Carousel from 'react-material-ui-carousel'
import CarouselPaper from './CarouselPaper'
import { graphql, useStaticQuery } from 'gatsby'
import parseStepper from '../../helpers/parseStepper'

function CarouselFun({ company, ...otherProps }) {
    const data = useStaticQuery(graphql`
    {
        allComponentsJson(filter: {component: {eq: "carousel"}}) {
          edges {
            node {
              id
              imagenes {
                company
                alt
                currentStep
                description
                subtitle
                title
                carousel
                src {
                  publicURL
                }
              }
              component
              stepper {
                company
                icons
                texts
              }
            }
          }
        }
      }
      `)

    let arrayItems = []
    const icons = parseStepper(data.allComponentsJson.edges[0].node.stepper.find(item => item.company === company).icons)
    const texts = data.allComponentsJson.edges[0].node.stepper.find(item => item.company === company).texts.map(text => { return {texto: text} })
    arrayItems = data.allComponentsJson.edges[0].node.imagenes.filter(item => item.company === company && item.carousel).map((row) => {
        return { img: (<img src={row.src?.publicURL} alt={row.alt} />), title: row.title, subtitle: row.subtitle, description: row.description, currentStep: row.currentStep }
    })


    // var itemsX = [
    //     {
    //         title: "¿Cómo funciona?",
    //         subtitle: 'Sin salir de casa',
    //         img: <Img fixed="2.svg" />,
    //         // img: <Img fixed={data.allFisaJson.nodes[0].imagenes[0].img} />,
    //         description: "Obtén un prestamo en 10 minutos",
    //         currentStep: 0,
    //     },
    //     {
    //         title: "¿Cómo funciona?",
    //         subtitle: 'Revisamos tu buen historial',
    //         img: <Img fixed={data.file.childImageSharp.fixed} />,
    //         description: "Si tienes un buen historial crediticio, éste préstamo es para tí.",
    //         currentStep: 1,
    //     },
    //     {
    //         title: "¿Cómo funciona?",
    //         subtitle: 'Validamos tus datos',
    //         img: <Img fixed={data.file.childImageSharp.fixed} />,
    //         description: "Es fácil, rápido y seguro",
    //         currentStep: 2,
    //     },
    //     {
    //         title: "¿Cómo funciona?",
    //         subtitle: 'Te damos una oferta',
    //         img: <Img fixed={data.file.childImageSharp.fixed} />,
    //         description: "Obtén tu préstamo para invertirlo como tu quieras",
    //         currentStep: 3,
    //     },
    // ]

    return (
        <div {...otherProps}>
            <Carousel indicators={false} timeout={500} interval={10000}>
                {
                    arrayItems.map((item, i) =>
                        <CarouselPaper
                            key={i}
                            title={item.title}
                            subtitle={item.subtitle}
                            img={item.img}
                            description={item.description}
                            currentStep={item.currentStep}
                            icons={icons}
                            texts={texts && texts.length > 0 ? texts : undefined}
                        />
                    )
                }
            </Carousel>
        </div>
    )
}

export default CarouselFun
