import AttachMoney from '@material-ui/icons/AttachMoney';
import Home from '@material-ui/icons/Home';
import AddComment from '@material-ui/icons/AddComment';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import HttpsIcon from '@material-ui/icons/Https';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

const parseIcon = (iconName) => {
    iconName = iconName?.toUpperCase()
    let icon

    switch(iconName) {
        case 'ATTACHMONEY':
            icon = AttachMoney
            break
        case 'HOME':
            icon = Home
            break
        case 'ADDCOMMENT':
            icon = AddComment
            break
        case 'HOWTOREG':
            icon = HowToRegIcon
            break
        case 'HTTPS':
            icon = HttpsIcon
            break
        case 'ACCESSALARM':
            icon = AccessAlarmIcon
            break
    }

    return icon

}

export default parseIcon