import React from 'react'
import { css } from '@emotion/react'
import { 
    CardElevation,
} from '@findep/mf-landings-core'

import Carousel from './Carousel.compilable'

function CarouselCard({company, ...otherProps}) {
    const root = css`
        width: 100%;
    `
    return (
        <CardElevation fullWidth {...otherProps}>
            <Carousel company={company} css={root}/>
        </CardElevation>
    )
}

export default CarouselCard
