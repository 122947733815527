import React from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@material-ui/core'
import { CustomizedStepper } from '@findep/mf-landings-core'

function CarouselPaper({img, title, subtitle, description, currentStep, imgSize, icons, texts, ...otherProps}) {
    const Img = img
    const center = css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & img {
            width: ${imgSize || 250}px;
        }
    `

    const emptyCss = css`
        height: ${imgSize || 250}px;
    `

    return (
        <Paper css={center} >
            <Typography color="primary" variant="h4">{title}</Typography>
            <div css={emptyCss}>
                {typeof Img === 'function' ? <Img /> : Img}
            </div>
            <Typography color="primary" align="center" variant="h6"><strong>{subtitle}</strong></Typography>
            <Typography variant="subtitle1" align="center">{description}</Typography>
            {currentStep >= 0 && <CustomizedStepper iconos={icons} currentStep={currentStep} movil texts={texts} />}
        </Paper>
    )
}

CarouselPaper.propTypes = {
    title: PropTypes.string.isRequired,
    img: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
    ]).isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    currentStep: PropTypes.number,
    imgSize: PropTypes.number,
}

export default CarouselPaper
